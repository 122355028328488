/* ==========================================================================

   NEWS SLIDER

========================================================================== */

.block-news {

	background: $white-alt;
	margin-bottom: 40px;
	padding-bottom: 30px;

	@include media-breakpoint-up(lg) {

		padding-bottom: 0;

	}

	.block-content {

		width: 100vw;
		max-width: 100%;

		@include media-breakpoint-down(lg) {

			position: relative;
			z-index: 2;

		}

		@include media-breakpoint-between(lg,xl) {

			margin-right: -40%;
		}


	}

	.news-slider-wrap {

		margin-left: 5vw;
		padding-top: 70px;

		@include media-breakpoint-up(lg) {

			margin-left: 30px;
			padding-top: 0;

		}

		.news-nav {

			position: absolute;
			bottom: -50px;
			right: 0;

			@include media-breakpoint-up(lg) {

				right: 37%;

			}

			@include media-breakpoint-up(xl) {

				bottom: 40px;
				right: 0;

			}

			.icon-prev {

				margin-right: 15px;

			}

		}

	}

}

.news-slider {

	width: 100vw;

	.slick-slide {

		margin: 0 10px;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

		@include media-breakpoint-up(lg) {

			margin: 0 25px;

		}

	}

	.slick-list {

		margin: 0 -10px;

		@include media-breakpoint-up(md) {

			margin: 0 -25px;

		}

	}

	.slick-active {

		opacity: 1;

	}

	.news-item {

		width: 168px!important;
		text-align: left;

		@include media-breakpoint-up(sm) {

			width: 220px !important;

		}

		@include media-breakpoint-up(md) {

			width: 380px !important;

		}

		@include media-breakpoint-up(xxxl) {

			width: 450px !important;

		}

		&:hover {

			.post-featured {

				transform: scale(1.1);

			}

		}

		.post-link {

			width: 100%;
			height: 100%;
			display: inline-block;
			margin-bottom: 20px;
			overflow: hidden;

		}

		.post-featured {

			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			width: 100%;
			height: 168px;
			transition: transform 0.4s ease-in-out;

			@include media-breakpoint-up(sm) {

				height: 220px;

			}

			@include media-breakpoint-up(md) {

				height: 380px;

			}

			@include media-breakpoint-up(xxxl) {

				height: 450px;

			}

		}

		.post-title {

			font-size: 15px;
			font-family: $font-secondary;
			//line-height: 40px;
			color: $black;

			@include media-breakpoint-up(md) {

				font-size: 35px;
				line-height: 40px;

			}

			a {

				color: $black;

			}

		}

		.post-meta {

			font-size: 13px;
			color: rgba($brand, 0.75);
			border-bottom: 2px solid $brand;
			margin-bottom: 0;
			padding-bottom: 5px;
			display: inline-block;

			@include media-breakpoint-up(md) {

				font-size: 14px;

			}

			@include media-breakpoint-up(xxxl) {

				font-size: 17px;

			}

		}

	}

}
