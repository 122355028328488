.contactform-wrapper {
  .tracking-information {
    display: none;
  }
  .wpcf7-not-valid-tip {
    color: white;
  }
  .validate-red {
    .wpcf7-not-valid-tip {
      color: red;
    }
  }
  form {
    background-color: white;
    text-align: center;

    .address-wrap {
      margin: 5px 0 65px;
      color: $brand;
      font-size: 14px;
      letter-spacing: 0.05em;
    }
    .address-wrapper label {
      margin-bottom: 20px;
    }
    .home-input,
    .work-input,
    .office-input,
    .phone-input,
    .sms-input,
    .email-input {
      display: inline-block;
      margin: 0 5px;
      @include media-breakpoint-up(md) {
        margin: 0 25px 35px;
      }
      @include media-breakpoint-up(lg) {
      }
      span.wpcf7-list-item {
        margin: 0;
        label, .js-auto-tab-container {
          font-size: 20px;
          .wpcf7-list-item-label {
            display: inline-block;
            position: relative;
            padding-left: 35px;
            margin-right: 5px;
            @include media-breakpoint-up(md) {
              padding-left: 60px;
              margin-right: 10px;
            }
            opacity: 1;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 0.21em;
            cursor: pointer;
            &:before {
              position: absolute;
              width: 30px;
              height: 30px;
              background-size: 15px;
              top: -5px;
              @include media-breakpoint-up(md) {
                width: 50px;
                height: 50px;
                background-size: 25px;
                top: -14px;
              }
              content: "";
              left: 0;
              display: block;
              background-color: $brand;
              border-radius: 50%;
              background-image: url("../images/phone_24px.svg");
              background-position: center center;
              background-repeat: no-repeat;
              opacity: 1;
            }
          }
        }
      }
    }

    .consultation-validation {
      display: none;
      color: red;
      margin-bottom: 20px;
    }
    .address-wrap {
      .js-address-validation,
      .validation,
      .home-helper,
      .work-helper,
      .office-helper {
        display: none;
        text-align: center;
        label {
          text-align: center;
        }
        &.active {
          display: block;
        }
      }
      .validation, .js-address-validation {
        color: red;
      }
    }

    .email-input span.wpcf7-list-item label .wpcf7-list-item-label:before {
      background-image: url("../images/email_24px.svg");
      background-size: 22px;
    }
    .office-input span.wpcf7-list-item label .wpcf7-list-item-label:before {
      background-image: url("../images/domain_24px.svg");
      background-size: 22px;
      cursor: pointer;
    }
    .work-input span.wpcf7-list-item label .wpcf7-list-item-label:before {
      background-image: url("../images/store_24px.svg");
      background-size: 22px;
      cursor: pointer;
    }
    .home-input span.wpcf7-list-item label .wpcf7-list-item-label:before {
      background-image: url("../images/home_24px.svg");
      background-size: 22px;
      cursor: pointer;
    }
    .sms-input span.wpcf7-list-item label .wpcf7-list-item-label:before {
      background-image: url("../images/text_24px.svg");
      background-size: 22px;
    }

    .input-checkbox-wrap {
      display: inline-block;
      margin: 10px 10px 0px;
    }

    .form-inner-wide {
      width: auto;
      max-width: 1000px;
      margin: 0 auto;
      padding: 0 15px;
    }
    .form-inner-narrow {
      width: auto;
      max-width: 650px;
      margin: 0 auto;
      padding: 0 15px;
    }

    textarea {
      border: none;
      background: rgba($brand, 0.1);
      width: 100%;
      height: 200px;
      border-bottom: solid 1px $brand;
      color: $brand;
      padding: 10px;
    }
    .subject-wrapper {
      padding-top: 50px;
      padding-bottom: 50px;
      color: white;
      background-color: $brand;
      h4 {
        color: white;
        text-transform: uppercase;
      }
      input[name="subject"] {
        display: none;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      display: block;
      li.other-subject-line {
        width: 70%;
        float: left;
        text-align: left;
        margin: 0 2.5%;
        opacity: 0;
        &.active {
          opacity: 1;
        }
        label {
          color: $white;
        }
        input[type='text'] {
          width: 100%;
          background: transparent;
          border: none;
          border-bottom: solid 1px white;
          color: white;
        }
      }
      li.option {
        @extend .btn;
        display: block;
        float: left;
        text-transform: uppercase;
        background-color: white;
        color: $brand;
        padding-left: 0;
        padding-right: 0;
        opacity: 0.4;
        @include transition(0.4s);
        width: 45%;
        margin: 0 2.5% 25px;
        &:before {
          background-color: #d8d8d8;
        }
        span {
          font-size: 10px;
        }
        @include media-breakpoint-up(sm) {
          width: 28%;
          margin: 0 2.5% 25px;
        }
        @include media-breakpoint-up(md) {
          width: 20%;
          margin: 0 2.5% 25px;
        }
        @include media-breakpoint-up(xl) {
          span {
            font-size: 14px;
          }
        }
        &:hover {
          color: $brand;
          @include media-breakpoint-up(xl) {
            opacity: 0.8;
          }
          &:before {
            display: none;
          }
        }
        &.active {
          opacity: 1;
          &:hover {
            @include media-breakpoint-up(xl) {
              opacity: 0.6;
            }
          }
        }
      }

      &.altcolours {
        li.option {
          width: 47%;
          margin: 0 1.5% 15px;

          @include media-breakpoint-up(md) {

            width: 22%;
            margin: 0 1.5% 15px;
          }
          font-size: 12px;
          background-color: #909090;
          color: white;
          &.active {
            background-color: $brand;
          }
        }
      }
    }
    .form-personal-details {
      border-top: 1px lighten($grey, 20%) solid;
    }
    .form-personal-details,
    .form-contact-details {
      h4 {
        margin: 15px 0;
        @include media-breakpoint-up(md) {
          margin: 45px 0;
        }
      }
    }

    select {
      width: 95%;
      font-size: 14px;
      background: transparent;
      border: solid 1px rgba($grey-dark, 0.5);
      -webkit-appearance: none;
      -webkit-border-radius: 0;
      border-radius: 0;
      background-image: url('../images/down-arrow.png');
      background-position: 95% 28px;
      background-size: 14px;
      background-repeat: no-repeat;
      padding: 15px;
      display: inline-block;
      max-width: 330px;
      margin: 0 auto;
      &[name='personal-title'] {
        border: none;
        border-bottom: solid 1px rgba($grey-dark, 0.5);
        background-position: 100% 15px;
        padding: 8px 0 8px 10px;
      }
    }

    label, .js-auto-tab-container {
      text-align: left;
      font-size: 18px;
      color: $black;
      width: 100%;
      span {
        font-size: 10px;
      }
      input[type='tel'],
      input[type='email'],
      input[type='text'] {
        background: transparent;
        border: none;
        width: 95%;
        margin: 10px auto 0;
        font-size: 14px;
        border-bottom: solid 1px rgba($grey-dark, 0.5);
        padding: 10px 0;
        color: black;
      }

    }
    .checkbox-wrap {
      margin: 50px 0;
      label {
        text-align: center;
        span {
          font-size: 14px;
          line-height: 1.6;
          opacity: 1;

        }
        a {
          color: $brand;
        }
      }
    }
    button {
      margin-bottom: 100px;
    }

    h4.question {
      margin-bottom: 25px;
    }
    p.helper {
      font-size: 15px;
      line-height: 1;
    }
    #timeoutput {
      display: none;
    }
    .timeoutput-view {
      text-align: center;
      color: $brand;
      font-size: 15px;
      border: none;
      pointer-events: none;
    }
    .slider-wrap {
      .wpcf7-not-valid-tip {
        font-size: 15px;
        opacity: 0.8;
      }

      .noUi-target {
        border-radius: 25px;
        background-color: #d7dff0;
        position: relative;
        width: calc(100% - 100px);
        margin: 25px 50px;
        height: 6px;
        box-shadow: none;
        border: 0;
        &.friday-selector:after {
          content: '18:00';
        }
        &:before {
          content: "08:00";
          display: block;
          position: absolute;
          top: -7px;
          left: -50px;
          font-size: 15px;
          color: $brand;
        }
        &:after {
          content: "20:00";
          display: block;
          position: absolute;
          top: -7px;
          right: -50px;
          font-size: 15px;
          color: $brand;
        }
        .noUi-connect {
          background: $brand;
        }
        .ui-slider-handle {
          top: 0;
          width: 50px;
          @include media-breakpoint-up(md) {
            width: 100px;
          }
          &:focus,
          &:hover {
            outline: none;
          }
        }
        .noUi-handle {
          background: $brand;
          border: none;
          height: 14px;
          width: 14px;
          right: -13px;
          top: -4px;
          border-radius: 25px;
          box-shadow: none;
          cursor: pointer;
          &:after, &:before {
            display: none;
          }
        }
      }
    }

    .input-radio-wrap {
      margin: 0 auto;
      @extend .row;
      .radio-grade {
        @extend .col-6;
        @extend .col-md-3;
        text-transform: uppercase;
        text-align: left;
        height: 45px;
        padding: 0;
        font-size: 15px;
        @include media-breakpoint-up(md) {
          height: 75px;
          padding: 0 15px;
        }
        .wpcf7-list-item {
          margin: 0;
          label {
            position: relative;
            padding-left: 25px;
            line-height: 14px;
            input[type='radio'] {
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }

    #advice-requests {
      display: none;
    }
  }

  .steps-wrap {
    background-color: white;
    text-transform: uppercase;
    border: solid 1px $grey;
    overflow: hidden;
    .col-12 {
      padding: 0;
      display: none;
      @include media-breakpoint-up(md) {
        display: initial;
      }
      &:nth-of-type(2) {
        .step {
          border-left: solid 2px $grey;
          border-right: solid 2px $grey;
        }
      }
      &.active {
        display: block;
        .step {
          background-color: $brand;
        }
        span {
          color: white;
        }
      }
      .step {
        background-color: white;
        display: block;
        padding: 15px;
        letter-spacing: 0.1em;
        color: #6E6E6E;
        transform: skew(-25deg);
        text-align: center;
        font-size: 14px;
        @include media-breakpoint-up(sm) {
          font-size: 11px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 14px;
        }

        span {
          transform: skew(25deg);
          display: block;
        }
      }
    }
  }

  .form-wrap {
    padding: 50px 15px;

    background-color: white;
    &.form-arrange-consult {
      padding-left: 40px;
    }
    .inner {
      width: 100%;
      max-width: 1300px;
      margin: 0 auto;
    }
  }
  #dateinput {
    display: none;
  }
  .ui-datepicker-inline {
    width: 100%;
    margin: 15px 0 50px;
    padding: 0;
    border-color: #D6D6D6;
    .ui-datepicker-title {
      font-weight: 400;
      text-transform: uppercase;
    }
    .ui-widget-header {
      border-radius: 0;
      background-color: #F2F2F2;
      border: none;
      padding: 10px 0;
    }
    .ui-datepicker-next,
    .ui-datepicker-prev {
      border: none !important;
      right: 0 !important;
      top: 0 !important;
      margin-top: 10px !important;
      background-color: transparent !important;
      span {
        background-position: center center;
        background-size: contain;
      }
    }
    .ui-datepicker-next {
      span {
        background-image: url('../images/next-arrow.png');
        cursor: pointer;
      }
    }

    .ui-datepicker-prev {
      span {
        background-image: url('../images/back-arrow.png');
        cursor: pointer;
      }
    }

    .ui-datepicker-calendar {
      & * {
        font-family: Avenir, sans-serif;
      }
      thead {
        th {
          font-weight: normal;
          text-transform: uppercase;
        }
      }
      tbody {
        tr {
          td {
            padding: 5px 1px;
            a {
              width: 30px;
              margin: 0 auto;
              text-align: center;
              height: 30px;
              line-height: 30px;
              border: none;
              background: transparent;
              padding: 0;
              color: $brand;
            }
            &.ui-datepicker-unselectable {
              span {
                width: 30px;
                margin: 0 auto;
                text-align: center;
                height: 30px;
                line-height: 30px;
                border: none;
                background: transparent;
                padding: 0;
              }
            }
            &.ui-datepicker-current-day {
              a {
                background: $brand;
                border-radius: 50%;
                color: white;
              }
            }
          }
        }
      }
    }
  }

  h3.phone-helper-text {
    text-transform: uppercase;
    color: $brand;
    font-size: 14px;
    max-width: 400px;
    margin: 0 auto 50px;
    @include media-breakpoint-up(md) {

      font-size: 18px;
    }
    letter-spacing: 0.1em;
    a {
      color: $brand;
    }
  }
  .summary {
    margin: 25px auto;
    border: solid #D6D6D6 1px;
    h3 {
      background-color: $brand;
      color: #fff;
      text-transform: uppercase;
      padding: 20px 0;
      font-size: 16px;
      letter-spacing: 0.1em;
      margin: 0;
    }
    .cell {
      text-align: left;
      padding-left: 30px;
      .submitted-data,
      .submitted-date,
      .submitted-time,
      .date-time-placeholder {
        color: $brand;
        @include media-breakpoint-between(sm, xl) {
          clear: both;
          display: block;
        }
      }
      @include media-breakpoint-between(md, lg) {
        .submitted-date, .submitted-time {
          display: inline-block;
        }
      }
      @include media-breakpoint-only(xl) {
        .submitted-date, .submitted-time {
          display: table-cell;
        }
      }
    }
  }

  .step-3-col {
    h4 {
      margin: 50px 0;
    }
  }

  .step-1-col,
  .step-2-col,
  .step-3-col,
  .step-4-col {
    display: none;
    &.active {
      display: block;
    }
  }

  .step-4-col .wpcf7-not-valid-tip {
    display: none !important; // Overriding horribly styled CF7 validation in favour of JS validation
  }

  .wpcf7-response-output {
    font-size: 20px;
    margin: 0;
    color: black;
    border: none;
    &.wpcf7-validation-errors {
      color: red;
    }
    &.wpcf7-mail-sent-ng {
      color: $brand;
    }
  }

  &.webinar-block {
    form {
      input[type=email],
      input[type=text] {
        padding: 0;
        border-bottom: 1px solid #dedede;
      }

      label {
        font-size: 14px;
        color: #6e6e6e;
      }

      .radio-grade {
        height: auto;
        padding: 0px;
      }
      .wpcf7-not-valid-tip {
        color: #f00;
      }

      .wpcf7-response-output {
        text-align: center;
        font-size: 14px;
      }

      .label-wrapper {
        p {
          margin-bottom: 0;
          margin-top: 5px;
        }
      }

      p.tiny {
        color: #dedede;
        font-size: 10px;
        text-align: left;
        line-height: 1.5;
      }

      .button-wrapper {
        button {
          margin: 10px auto 0;
          display: block;

          @include media-breakpoint-up(lg) {
            margin: 60px auto 0;
          }
        }
      }
    }
  }

}

