/* ==========================================================================

   COLOURS

========================================================================== */


$white: #fff;
$white-alt: #F4F6FB;
$light-grey: #F8F8F8;
$black: #212121;
$grey: #BFBFBF;
$grey-light: #fcfcfd;
$grey-dark: #222222;
$grey-darker: #2e2e2e;
$dark-grey: #2E2E2F;

$brand: #f01a3a;
$brand-light: #B2B9CA;
$border-color: #C7C7C7;
$border-light: #D6D6D6;
$border-vlight: #E0E0E0;

//$brand-medical: #077c9a;
//$brand-dental: #4a73ff;

//$brand: #f01a3a;
//$brand-light: #B2B9CA;
//$border-color: #C7C7C7;

$background-light: #FBF4F4;

$warn: #F72D2D;

/* ==========================================================================

   TYPE

========================================================================== */

$heading-color: $brand;
$heading-color-dark: #2C3946;
$text-color: #6E6E6E;

$font-base: 'Avenir', sans-serif;
$font-secondary: 'Baskerville', serif;
$font-avenir: $font-base;
$font-baskerville: $font-secondary;

$font-regular: 400;
$font-semibold: 500;
$font-bold: 700;
$font-heavy: 900;

$font-size: 14px;
$font-size-small: 12px;
$font-size-medium: 20px;
$font-size-large-title: 46px;


/* ==========================================================================

   GRID SETTINGS

========================================================================== */

$site-width: 1800px;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1800px
);
