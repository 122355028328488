// e.g '@include z-index(header)' on element;

$z-index: (
  header		: 1000,
  personaDrop	: 600,
  heroGradient  : 50,
  heroInner		: 60,
  aboveHero		: 100,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}
