/* ==========================================================================
   
   EVENT SEARCH

========================================================================== */

.gmw-results-wrapper.custom {
  width: 100%;
}

div.gmw-form-wrapper.default {
  .gmw-form-field-wrapper {
    label {
      display: none;
    }

    input[type='text'] {
      font-size: 15px;
      padding-left: 30px;
      padding-right: 125px;

      @include media-breakpoint-up(sm) {
        padding-right: 150px;
      }

      @include media-breakpoint-up(xl) {
        padding-right: 180px;
      }
    }

    i.gmw-locator-button {
      right: 125px;

      @include media-breakpoint-up(sm) {
        right: 150px;
      }

      @include media-breakpoint-up(xl) {
        right: 180px;
      }
    }
  }

  .gmw-submit-field-wrapper {
    display: none;
  }
}

.no-results {
  text-align: center;
  padding: 50px;
  background: white;
  width: 100%;
  margin: 0 15px;
  text-transform: uppercase;
  color: $brand;
  font-size: 18px;
  line-height: 2;
  letter-spacing: 2px;
  font-weight: bold;
  
  @include media-breakpoint-up(lg) {
    font-size: 30px;
    padding: 100px;
    line-height: 2.5;
    letter-spacing: 2px;
  }
}
