/* ==========================================================================

   NAVIGATION

========================================================================== */

header.primary {

  .nav {

    @extend .list-unstyled;
    padding-left: 10px;
    margin-bottom: 45px;

    @include media-breakpoint-up(lg) {

      margin-bottom: 0;
      flex-wrap: nowrap;
      flex: 1 0 auto;
      justify-content: center;

    }

    &.active {

      .menu-item {

        opacity: 1;
        transform: translateX(0);

        @for $item from 1 through 10 {
          &:nth-child(#{$item}) {
            transition-delay: ($item)*0.3s;
          }
        }

      }

    }

    .menu-item {

      @extend .list-unstyled;
      display: block;
      width: 100%;

      font-size: 14px;
      font-weight: 500;
      color: #000;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      line-height: 100%;

      margin-bottom: 25px;

      opacity: 0;
      transform: translateX(20px);
      transition: all 0.6s ease-in-out;

      @for $item from 1 through 10 {
        &:nth-child(#{$item}) {
          transition-delay: ($item)*0.1s;
        }
      }

      &:last-child {

        margin-bottom: 10px;

        @include media-breakpoint-up(lg) {

          margin-bottom: 0;

        }

      }

      @include media-breakpoint-up(lg) {

        opacity: 1;
        transform: translateX(0);

        display: inline-block;
        width: auto;

        margin-right: 30px;
        margin-bottom: 0;
        font-size: 12px;

        &:last-child {

          margin-right: 0;

        }

      }

      @media (min-width: 1500px) {

        font-size: 14px;

      }

      a {

        position: relative;
        color: #000;

        &:hover {

          color: $brand;

        }

      }

      &.menu-item-has-children {

        &.active {

          & > a:after {

            transform: rotate(-180deg);

          }

        }

        .drop-mobile.active {

          &:after {

            transform: rotate(-180deg);

          }

        }

        & > a:after {

          @include icons;
          content: '\e901';
          display: inline-block;
          color: $brand;
          font-size: 11px;
          margin-left: 15px;
          transform: rotate(0);
          transition: transform 0.4s ease-in-out;

          @include media-breakpoint-up(lg) {

            font-size: 8px;

          }

        }

      }

      > .sub-menu {

        display: none;
        padding-top: 25px;
        padding-left: 25px;

        @include media-breakpoint-up(lg) {

          display: none !important;

        }

        a {

          &:before {

            content: '';
            position: absolute;
            top: 8px;
            left: -25px;
            background: $brand;
            height: 2px;
            width: 15px;

          }

        }

      }

    }

  }

}

.persona-dropdown {
  &.right {
    right: 0;
  }
  &.left {
    left: 0;
  }
  position: fixed;
  top: 130px;

  @include z-index(personaDrop);

  width: 65%;
  background: #d1d2d5;

  transform: translateY(-110%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s ease-in-out;

  &.callout-visible {
    top: 210px;
  }


  @include media-breakpoint-down(md) {

    &:not(.persona-dropdown-index) {

      display: none;

    }

  }

  @include media-breakpoint-up(xl) {

    width: 50%;

  }

  &.persona-dropdown-index {

    position: static;
    margin-left: auto;
    margin-right: auto;

    text-align: center;
    padding-top: 60px;

    .dropdown-inner {

      padding-left: 0;
      padding-top: 40px;

      @include media-breakpoint-up(lg) {

        padding-top: 92px;

      }

    }

    .persona-dropdown-title {

      font-family: $font-secondary;
      font-size: 45px;
      color: $brand;
      display: inline-block;

    }

    .persona-list .persona-item a:after {

      right: auto;
      left: -120px;

      @media (min-width: 1450px) {

        left: -220px;

      }

    }

  }

  &.active {

    opacity: 1;
    visibility: visible;
    transform: translateY(0);

    // Animate list items
    .persona-list .persona-item {

      opacity: 1;
      transform: translateY(0);

      &:first-child {
        transition-delay: 0.6s;
      }

      @for $item from 2 through 12 {
        &:nth-child(#{$item}) {
          transition-delay: ($item)*0.15s+0.3s;
        }
      }

    }

  }

  .dropdown-inner {

    position: relative;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: calc(5vw + 30px);

  }

  .persona-list {

    list-style-type: none;
    padding: 0;
    margin: 0;

    .persona-item {

      position: relative;
      font-size: 20px;
      text-transform: uppercase;
      color: $black;
      letter-spacing: 0.2em;
      margin-bottom: 30px;

      opacity: 0;
      transform: translateY(20px);
      transition: all 0.6s ease-in-out;

      &:last-child {

        margin-bottom: 0;

      }

      &:hover a {

        &:after {

          width: 80px;
          opacity: 1;

          @media (min-width: 1450px) {

            width: 160px;

          }

        }

      }

      a {

        position: relative;
        color: $black;

        &:after {

          content: '';
          position: absolute;
          top: 50%;
          right: -120px;

          transform: translateY(-50%);
          background: $brand;
          width: 0;
          height: 2px;

          opacity: 0;
          transition: all 0.3s ease-in-out;

          @media (min-width: 1450px) {

            right: -220px;

          }

        }

      }

    }

  }
}
