.portfolio-section {
  @include media-breakpoint-up(sm) {
    margin: 10px 60px 60px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 80px;
  }

  &__title {
    font-size: 18px;
    color: $dark-grey;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  &__line {
    content: '';
    position: absolute;
    height: 2px;
    transform: rotate(90deg);

    @include media-breakpoint-down(sm) {
      align-self: center;
    }
  }

  &__top {
    min-height: 40vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 6rem 4rem;
    position: relative;
    text-align: center;
    background-color: $white-alt;

    @include media-breakpoint-down(sm) {
      padding: 4rem 3rem;
    }

    @include media-breakpoint-up(md) {
      margin: 5rem 4rem;
    }

    &-title {
      @extend .portfolio-section__title;
      display: flex;
      justify-content: center;
      margin: 2rem 0;

      &::before {
        content: '';
        position: absolute;
        width: 60px;
        height: 2px;
        background-color: $brand;
        margin-top: -30px;
      }
    }

    &-content {
      max-width: 380px;
      align-self: center;
      margin-bottom: 3rem;
      font-size: 17px;
    }

    &-sub-title {
      display: flex;
      justify-content: center;
      font-size: 18px;
      color: $dark-grey;
      letter-spacing: 3px;
      text-transform: uppercase;
      margin-bottom: 3rem;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        margin: 0 auto 2rem auto;
      }

      &::after {
        @extend .portfolio-section__line;

        width: 120px;
        bottom: 0;
        background-color: $brand;
      }

      &--count {
        display: flex;
        align-items: center;
        color: $brand;
        font-size: 18px;
        margin: 0;
      }
    }
  }

  &__main-body {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: -9.5rem 0;
    padding: 10rem 4rem;
    border: $grey 1px solid;

    @include media-breakpoint-down(sm) {
      margin: -9.5rem 2rem;
      padding: 16rem 4rem;
    }

    &-content {
      display: flex;
      justify-content: center;
      align-self: center;

      @include media-breakpoint-up(sm) {
        margin: 1rem 0;
      }

      @include media-breakpoint-down(md) {
        width: 145%;
      }
    }

    &-priority-item,
    &-risk-item {
      @include media-breakpoint-up(sm) {
        max-width: 50%;
      }
    }

    &-title {
      @extend .portfolio-section__title;

      margin: 0 -2rem .5rem -2rem;

      &--risk {
        @extend .portfolio-section__title;

        @include media-breakpoint-down(sm) {
          margin: 3rem -2rem .5rem;
        }
      }
    }

    &-sub-title {
      font-size: 18px;
    }

    &__select {
      &--priorities,
      &--risks {
        margin: 0;

        .slick-list {
          overflow: visible!important;
        }

        @include media-breakpoint-down(sm) {
          .slick-track {
            display: grid !important; // Override Slick CSS
          }
        }
      }

      &--priorities__container,
      &--risks__container {
        margin: 2rem 0;
      }
    }
  }

  &__detail {
    min-height: 40vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 6rem 4rem;
    position: relative;
    text-align: center;
    background-color: $white-alt;

    @include media-breakpoint-down(sm) {
      padding: 4rem 3rem;
    }

    @include media-breakpoint-up(md) {
      margin: 5rem 4rem 0;
    }

    &-subtitle {
      @extend .portfolio-section__title;
      display: flex;
      justify-content: center;
      margin: 2rem 0;

      &::after {
        @extend .portfolio-section__line;

        height: 120px;
        width: 2px;
        top: -60px;
        left: 50%;
        background-color: $brand;
        transform: rotate(180deg);
      }
    }

    &-title {
      font-size: 32px;
      text-transform: uppercase;
      color: $dark-grey;
      margin-bottom: 4rem;

      @include media-breakpoint-up(md) {
        font-size: 40px;
      }
    }

    &-content {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      min-height: 15vh;

      &::before {
        @extend .portfolio-section__line;

        width: 200px;
        align-self: center;
        background-color: $grey;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      &__heading {
        font-size: 18px;
        color: $dark-grey;
        margin-bottom: 1rem;
      }

      &__assets {
        width: 100%;
        display: flex;
        align-self: center;
        justify-content: space-evenly;

        @include media-breakpoint-down(sm) {
          padding-bottom: 3rem;
          border-bottom: 2px solid $grey;
          margin-bottom: 3rem;
        }

        &__container {
          display: flex;
          flex-direction: column;
        }

        &__item {
          text-align: left;

          &--red {
            font-size: 26px;
            color: $brand;
            margin: 0;
            padding: 1rem 0;
            text-align: center;

            @include media-breakpoint-up(md) {
              min-width: 4.5rem;
            }
          }
        }

        &__text {
          font-size: 14px;
          color: $grey;
          width: 60%;

          @include media-breakpoint-up(md) {
            margin-top: 3rem;
          }

          @include media-breakpoint-down(md) {
            width: 100%;
            margin-top: 1rem;
          }
        }
      }

      &__portfolio {
        display: flex;
        align-self: center;

        @include media-breakpoint-up(sm) {
          width: 90%;
          display: flex;
          justify-content: center;
        }
      }
    }

    &__link {
      margin-top: -40px;

      @extend .benefit-item__link;

      &--btn {
        @extend .benefit-item__link--btn;

        bottom: 0;

        @include media-breakpoint-down(sm) {
          left: -20px;
        }
      }
    }
  }
}

.priority_selector,
.risk_selector {
  background: #b6b6b6 !important; //override sitewide 'button' styling
  margin: 0.25rem;
  padding: 1rem !important; //override sitewide 'button' styling
  max-width: calc(100% - 2vw) !important; //override sitewide 'button' styling
}

.priority_selector.active,
.risk_selector.active {
  background: $brand !important; //override sitewide override
  margin: 0.25rem;
  padding: 1rem !important; //override sitewide override
}

body.page-template-templatescdv-dpm-index-php {
  .hero--page {
    overflow: visible;
  }
}