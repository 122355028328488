/* ==========================================================================

   BUTTONS

========================================================================== */

.btn,
button {

    cursor: pointer;
    position: relative;
    display: inline-block;
    background: $brand;
    color: $white;
    font-size: 10px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    white-space: normal;
    padding: 18px 40px;
    transform: skew(-25deg) scale(1);
    border: none;
    border-radius: 0;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;

    @include media-breakpoint-down(sm) {

        max-width: calc(100% - 10vw);

    }

    @include media-breakpoint-up(sm) {

        font-size: 14px;

    }

    @include media-breakpoint-up(md) {

        padding: 18px 40px;

    }

    @include media-breakpoint-up(xl) {

        font-size: 16px;
        padding: 24px 50px;

    }

    &:before {

        content: '';
        background: darken($brand,10%);
        position: absolute;
        top: 15%;
        left: 0;
        right: 0;
        width: 90%;
        height: 100%;
        left: 5%;
        /* opacity: 0; */
        transform: translate(-100%, 100%);
        transition: all 0.3s ease-in-out;

    }

    &:hover {

        color: $white;

        &:before {

            transform: translate(0);
            opacity: 1;

        }

    }

    &:active {

        transform: skew(-25deg) scale(0.97);

    }

    &:focus {

        box-shadow: none;

    }

    span {

        transform: skew(25deg);
        display: block;
        line-height: 22px;

    }

    &.btn--white {

        background: $white;
        color: $brand;

        &:before {

            background: darken($white, 5%);

        }

    }
    &.btn--black {
        background: $black;
    }

    &:disabled {
        opacity: 0.5;
        cursor: no-drop;
    }


}

.link-plus {

    font-size: 14px;
    color: $black;
    letter-spacing: 0.2em;

    &--right:before {

        float: right;
        margin-right: 0;
        margin-left: 10px;

    }

    &:hover {

        &:before {

            transform: rotate(90deg);

        }

    }

    &:before {

        @include icons;
        content: '\e908';
        display: inline-block;
        color: $brand;
        margin-right: 10px;
        position: relative;
        top: 2px;
        font-size: 17px;

        transform: rotate(0);
        transition: transform 0.3s ease-in-out;

    }

    @include media-breakpoint-up(md) {

        font-size: 16px;

    }

}

.learn-more {
  position:absolute;
  bottom:0;
  right:0;
  z-index:10;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  text-transform:uppercase;
  height:60px;

  @include media-breakpoint-up(lg) {
    height:80px;
  }

  > span {
    background:$brand;
    color:#fff;
    padding:0 20px;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    position:relative;
    height:100%;
  }
  .learn-more-text {
    font-size:.8em;
    line-height:1em;
    transform:translateX(100%);
    transition: transform .5s ease-in-out;
  }

  .icon-plus {
    width:60px;

    @include media-breakpoint-up(lg) {
      width:80px;
    }

    &:before {
      font-size:20px;

      @include media-breakpoint-up(lg) {
        font-size:24px;
      }
    }
  }
}
