.webinar-list-banner {
  overflow: hidden;

  .content-padding {
    padding-top: 40px;
    padding-bottom: 40px;
    @include media-breakpoint-up(lg) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    @include media-breakpoint-up(xl) {
      padding-top: 160px;
      padding-bottom: 160px;
    }
  }

  .title-area {
    @include media-breakpoint-up(lg) {
      padding-right: 80px;
    }
  }
  .image-container {
    z-index: -1;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      width: 70%;
      right: 0;
      top: 0;
      height: 100%;
      position: absolute;
    }
    @include media-breakpoint-up(lg) {
      width: 40%;
    }

    @include media-breakpoint-up(xxl) {
      width: 50%;
    }

    &:before {
      @include media-breakpoint-up(lg) {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        right: 50%;
        background: white;
        transform: skew(-30deg);
      }

      @include media-breakpoint-between(md, lg) {
        right: 40%;
      }

      @include media-breakpoint-up(xxl) {
        right: 70%;
      }
    }

    img {
      @include media-breakpoint-up(lg) {
        object-fit: cover;
        height: 100%;
        max-width: none;
        right: 0;
        position: absolute;
        z-index: -1;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .webinar-list {
    background: #fff;
    transform: skew(-30deg, 0deg);

    .title {
      transform: skew(30deg, 0deg);
    }
  }
}

.list-title {
  .title {
    position: relative;
    font-size: 15px;
    line-height: 1;
    margin-bottom: 30px;
    color: $brand;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    padding-left: 40px;

    &.tight {
      margin-bottom: 15px;
    }

    @include media-breakpoint-up(lg) {
      line-height: 1.5;
      padding-left: 70px;
    }

    &:before {
      content: '';
      height: 2px;
      width: 20px;
      display: inline-block;
      background: $brand;
      left: 0;
      position: absolute;
      top: 10px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 5px;
        display: block;
      }

      @include media-breakpoint-up(xl) {
        width: 50px;
        margin-right: 40px;
        display: inline-block;
        margin-bottom: 0;
      }
    }
  }
}

.webinar-cta-banner {
  background: $brand;
  color: white;
}

.webinar-registration {
  min-height: calc(100vh - 52px);
  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - 81px);
  }

  .webinar-block {
    padding: 1.5rem 3rem;

    @include media-breakpoint-up(md) {
      padding: 3rem;
    }
    @include media-breakpoint-up(lg) {
      padding: 6rem 3rem;
    }
    @include media-breakpoint-up(xl) {
      padding: 6rem 8rem;
    }
  }

  .bg-light-blue {
    background: $white-alt;
  }

  .heading {
    font-size: 22px;
    color: $black;
    text-transform: uppercase;
    opacity: 0;
    transform: translateY(15px);
    animation: heroHomeFadeIn 0.8s ease-in-out forwards;
    animation-delay: 0.2s;
    letter-spacing: 0.15em;
    @include media-breakpoint-up(lg) {
      font-size: 28px;
    }
  }

  .sub-title {
    color: $brand;
    font-size: 18px;
    font-family: $font-secondary;
    line-height: 22px;
    margin-bottom: 15px;
    opacity: 0;
    transform: translateY(15px);
    animation: heroHomeFadeIn 0.8s ease-in-out forwards;

    @include media-breakpoint-up(md) {
      font-size: 26px;
      line-height: 40px;
      margin-bottom: 10px;
    }
  }
}
