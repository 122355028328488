/* ==========================================================================

   HERO

========================================================================== */

$width-large: 1600px;

.hero {
  position: relative;
  transition: all 0.4s ease-in-out;

  &.hero-vertical-line {
    &:after {
      content: '' !important; // Override default :after styling
      position: absolute !important; // Override default :after styling
      bottom: -50px;
      left: 5vw;
      z-index: 100;
      background: $brand;
      width: 2px;
      height: 100px !important; // Override default :after styling
      transform: rotate(0) !important; // Override default :after styling
    }
  }

  .divider-line {
    height: 2px;
    width: 100%;
    max-width: 50px;
    margin-bottom: 10px;
  }

  &.playing-video {
    @include media-breakpoint-up(xl) {
      margin-bottom: 80px;

      .hero-content {
        padding-top: 300px !important; // Overriding legacy styling :-(
        padding-bottom: 300px !important;  // Overriding legacy styling :-(
      }
    }

    .hero-video-container {
      @include media-breakpoint-down(md) {
        width: auto;
        top: 50%;
      }
    }

    .embed-container > iframe {
      @include media-breakpoint-up(md) {
        position: fixed;
      }
    }

    .hero-content {
      .title,
      .sub-title {
        opacity: 0;
        visibility: hidden;
        transition: all 0.1s linear;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }

  &.hero-featured-sticky {
    .hero-inner {
      @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
      }


      .hero-content {
        @include media-breakpoint-up(md) {
          width: 50%;
          flex-grow: 1;
          display: flex;
          align-items: center;
          .title,
          .desc {
            max-width: 60%;
          }
        }
      }

      .featured-sticky {
        width: 50%;
        opacity: 0;
        transform: translateY(20px);
        animation: heroHomeFadeIn 0.6s cubic-bezier(0.02, 0.5, 0.99, 1) forwards;

        @include media-breakpoint-up(md) {
          margin-left: auto;
        }


        @media (min-width: 1400px) {
          width: 65%;
          max-width: 800px;
        }

        .post-box-small {
          @include media-breakpoint-up(lg) {
            margin-bottom: 0;
          }


          @media (min-width: 1400px) {
            display: flex;
            width: 800px;
          }

          .title {
            font-size: 35px;
            line-height: 40px;
            text-transform: initial;
            max-width: 100%;
            opacity: 1;
            transform: translateY(0);

            @include media-breakpoint-up(md) {
              max-width: 60%;
            }
          }

          .post-box-image {
            height: auto;
            display: none;

            @media (min-width: 1400px) {
              height: 364px;
              width: 50%;
              order: 2;
              display: block;
            }

            @include media-breakpoint-up(xxl) {
              height: 380px;
            }
          }
        }
      }
    }
  }

  // All !important declarations are to overwrite legacy !importants/styling :'(
  &__generic {
    background-color: $white-alt;
    padding-top: 50px;
    &:before,
    &:after {
      background: none transparent !important;
    }

    .generic__content {
      display: flex;
      @include media-breakpoint-down(md) {
        display: inline-block;
      }
    }

    .generic__title {
      @extend .title;
      font-size: 34px !important;
      max-width: 100% !important;
      color: $brand !important;
      letter-spacing: .2em;
      text-transform: uppercase;
      @include media-breakpoint-down(lg) {
        letter-spacing: .1em;
      }
    }

    @include media-breakpoint-up(sm) {
      .js-step-count {
        display: none;
      }
      .contact-section {
        display: block !important;
      }
    }

    .contact-section {
      text-align: right;
      letter-spacing: .4em;
      font-size: 14px;
      text-transform: uppercase;
      .telephone {
        color: $text-color;
      }
      @include media-breakpoint-down(md) {
        letter-spacing: .2em;
      }
    }
  }

  &:before {
    content: '';

    @include z-index(heroGradient);

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #fff, #fff 15%, rgba(255, 255, 255, 0) 100%);

    @include media-breakpoint-up(sm) {
      background: linear-gradient(90deg, #fff, #fff 45%, rgba(255, 255, 255, 0) 80%);
    }


    @include media-breakpoint-up(lg) {
      background: linear-gradient(90deg, #fff, #fff 55%, rgba(255, 255, 255, 0) 80%);
    }
  }

  .hero-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }

    > .bg {
      position: absolute;
      right: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: right center;
      width: 100%;
      height: 100%;
      opacity: 0;
      transform: translateX(30px);

      &.loaded {
        animation: heroBg 2s cubic-bezier(0.02, 0.5, 0.99, 1) forwards;
      }

      &.loaded.events {
        animation: heroBgEvents 2s cubic-bezier(0.02, 0.5, 0.99, 1) forwards;
      }

      @include media-breakpoint-up(sm) {
        width: 55%;
      }


      @include media-breakpoint-up(lg) {
        width: 50%;
      }


      &.mobile {
        @include media-breakpoint-up(xl) {
          display: none;
        }
      }

      &.desktop {
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }
  }

  .hero-content {
    position: relative;

    @include z-index(heroInner);

    transition: all 0.4s ease-in-out;
    padding-top: 50px;
    padding-bottom: 50px;

    @include media-breakpoint-up(md) {
      padding-top: 190px;
      padding-bottom: 190px;
    }


    @include media-breakpoint-up(lg) {
      padding-top: 190px;
      padding-bottom: 280px;
    }


    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  .hero-content {
    form {
      position: relative;
      max-width: 600px;
      width: 100%;
      margin-top: 30px;
      overflow: hidden;
      opacity: 0;
      transform: translateY(15px);
      animation: heroHomeFadeIn 0.8s ease-in-out forwards;
      animation-delay: 0.4s;

      @include media-breakpoint-up(md) {
        overflow: visible;
      }


      input {
        position: relative;
        height: 70px;
        width: 100%;
        padding-right: 180px;

        @include media-breakpoint-up(md) {
          width: 600px;
        }


        &:focus {
          box-shadow: none;
          border-color: #dfdfdf;
        }
      }

      .submit {
        position: absolute;
        top: 18px;
        right: -20px;
        height: 70px;

        @include media-breakpoint-up(md) {
          top: 6px;
        }
      }
    }
  }

  // Hero Home
  &.hero--home {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .title {
      font-size: 30px;
      letter-spacing: 0.05em;
      line-height: 41px;
      color: $black;
      text-transform: uppercase;
      margin-bottom: 0;
      opacity: 0;
      transform: translateY(15px);
      animation: heroHomeFadeIn 0.8s ease-in-out forwards;

      @include media-breakpoint-up(md) {
        font-size: 50px;
        line-height: 1.3;
        max-width: 60%;
      }


      @include media-breakpoint-up(xxl) {
        font-size: 60px;
        line-height: 1.6;
      }


      span {
        color: $brand;
        font-family: $font-secondary;
        font-size: 35px;
        text-transform: capitalize;

        @include media-breakpoint-up(md) {
          font-size: 55px;
        }


        @include media-breakpoint-up(xxl) {
          font-size: 95px;
        }
      }
    }
  }

  // Hero Page
  &.hero--page {
    &:before {
      @include media-breakpoint-up(lg) {
        background: linear-gradient(90deg, #fff, #fff 35%, rgba(255, 255, 255, 0) 80%);
      }
    }

    &:after {
      content: '';
      position: absolute;
      height: 2px;
      transform: rotate(90deg);

      @include media-breakpoint-down(sm) {
        align-self: center;
      }
    }

    &.hero--page-sm .hero-content {
      @include media-breakpoint-up(lg) {
        padding-top: 190px;
        padding-bottom: 160px;
      }
    }

    &.hero--page-xs .hero-content {
      @include media-breakpoint-up(lg) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

    &.hero--page-lg .hero-content {
      display: flex;
      height: 80vh;
      flex-direction: column;
      justify-content: center;
    }

    .hero-bg > .bg {
      @include media-breakpoint-up(lg) {
        width: 70%;
      }
    }

    + .container-fluid > .block-half-diag:not(.webinar-list-banner) {
      @include media-breakpoint-up(xl) {
        margin-top: -80px;
      }
    }

    &.hero-form {
      .hero-inner {
        .hero-content {
          .row {
            > .col-12:first-child {
              padding-left: 10vw;
              padding-right: 10vw;

              @include media-breakpoint-up(md) {
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
        }
      }

      .hero-content {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        @include media-breakpoint-up(md) {
          padding-left: 5vw;
          padding-right: 5vw;
        }


        @include media-breakpoint-up(lg) {
          padding-top: 30px;
        }


        .title {
          font-size: 2.5rem;
          max-width: 80%;
        }

        .desc {
          max-width: 60%;
        }

        form {
          position: relative;
          max-width: none;
          width: 100%;
          background: #fff;
          padding: 30px;
          height: 100%;

          @include media-breakpoint-up(md) {
            box-shadow: 2px 2px 10px rgba(#000, 0.25);
            max-width: 600px;
          }


          .title {
            color: $brand;
            font-size: 1.8rem;
          }

          .title, .desc {
            text-align: center;
          }

          .title, input, button {
            text-transform: uppercase;
          }

          .title, .desc, input, button {
            max-width: 100%;
          }

          .supporting {
            margin-top: 20px;
            margin-bottom: 0;
          }

          p {
            font-size: 1rem;
          }

          input {
            background: rgba($brand, 0.25);
            border: none;
            border-radius: 0;
            width: 100%;
            padding: 10px 20px;
            height: auto;
            margin-bottom: 20px;
          }

          .accept-terms {
            align-items: center;
          }

          .membership-code {
            input {
              height: 70px;
            }
          }

          button, .btn {
            transform: skew(0deg) scale(1);
            width: 100%;

            > span {
              transform: skew(0deg) scale(1);
            }
          }
        }
      }
    }

    .title {
      font-size: 30px;
      line-height: 41px;
      color: $black;
      text-transform: uppercase;
      opacity: 0;
      transform: translateY(15px);
      animation: heroHomeFadeIn 0.8s ease-in-out forwards;
      animation-delay: 0.2s;

      @include media-breakpoint-up(md) {
        font-size: 60px;
        line-height: 1.3em;
        max-width: 60%;
      }


      @include media-breakpoint-up(xxl) {
        font-size: 70px;
      }


      &.title-sm {
        @include media-breakpoint-up(md) {
          font-size: 40px;
          line-height: 61px;
          max-width: 100%;
        }
      }
    }

    .sub-title {
      color: $brand;
      font-size: 20px;
      font-family: $font-secondary;
      line-height: 22px;
      margin-bottom: 15px;
      opacity: 0;
      transform: translateY(15px);
      animation: heroHomeFadeIn 0.8s ease-in-out forwards;

      @include media-breakpoint-up(md) {
        font-size: 35px;
        line-height: 40px;
        margin-bottom: 10px;
      }
    }

    .desc {
      font-size: 17px;
      line-height: 1.8;
      color: $text-color;
      max-width: 250px;
      opacity: 0;
      transform: translateY(15px);
      animation: heroHomeFadeIn 0.8s ease-in-out forwards;
      animation-delay: 0.4s;

      p {
        font-size: inherit;
        line-height: inherit;
        color: inherit;
      }

      @include media-breakpoint-up(lg) {
        max-width: 340px;
      }


      @include media-breakpoint-up(xl) {
        max-width: 640px;
      }


      @media (min-width: 1400px) {
        max-width: 400px;
      }
    }
  }

  // Single Post
  &.hero--post {
    height: 260px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      height: 400px;
    }


    @include media-breakpoint-up(xl) {
      margin-bottom: 50px;
    }


    &:before {
      display: none;
    }

    .hero-content {
      padding-top: 60px;
      padding-bottom: 0;
    }

    .hero-bg > .bg {
      width: calc(100% + 30px);
    }
  }

  &.hero--video {
    overflow: hidden;
    transition: margin 0.3s ease-in-out;
    background-size: cover;

    &--black {
      background-color: $black;

      & * {
        color: white !important; // Override colours required for other pages
      }

      &:before {
        background-color: black !important; // Override pseudo element for other classes
        opacity: .6;
      }
    }
  }

  // Play
  .hero-video-play {
    position: relative;
    top: 50px;
    display: inline-block;
    text-align: center;
    width: 100%;

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10vw;
      width: auto;
    }


    &:hover {
      a:before {
        transform: translate(-50%, -50%) scale(1.3);
      }

      .icon {
        transform: scale(0.7);
      }
    }

    a {
      display: block;

      &:before {
        content: '\e909';

        @include icons;

        font-size: 100px;
        color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        transition: transform 0.4s cubic-bezier(0.42, 0, 0, 1);

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    .icon {
      font-size: 100px;
      color: rgba(0, 0, 0, 0.1);
      display: block;
      transform: scale(1);
      transition: transform 0.4s cubic-bezier(0.42, 0, 0, 1);
      margin-bottom: 50px;

      @include media-breakpoint-up(lg) {
        font-size: 360px;
        color: $white;
        opacity: 0.1;
        margin-bottom: 0;
      }
    }
  }

  .insight-video-play {
    position: relative;
    top: 50px;
    display: inline-block;
    text-align: center;
    width: 100%;

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 35vw;
      width: auto;
    }

    &:hover {
      a:before {
        transform: translate(-50%, -50%) scale(1.3);
      }

      .icon {
        transform: scale(0.7);
      }
    }

    a {
      display: block;

      &:before {
        content: '\e909';
        @include icons;
        font-size: 100px;
        color: $black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        transition: transform .4s cubic-bezier(0.42, 0, 0, 1);

        @include media-breakpoint-up(lg) {
          color:$white;
        }

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &.title-dark {
        &:before {
          color: $black;
        }

        .icon {
          color: $black;
        }
      }
    }

    .icon {
      font-size: 100px;
      color: rgba(0, 0, 0, 0.1);
      display: block;
      transform: scale(1);
      transition: transform .4s cubic-bezier(0.42, 0, 0, 1);
      margin-bottom: 50px;

      @include media-breakpoint-up(lg) {
        font-size: 360px;
        color: $white;
        opacity: 0.1;
        margin-bottom: 0;
      }
    }
  }

  .hero-video-container {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    transform: translateY(-50%);
    display: none;

    @media (min-width: 400px) {
      top: calc(50% - 25px);
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-up(lg) {
      top: 0;
      height: 100%;
      width: 100%;
      transform: translateY(0);
    }

    &--dpm {
      height: 100%;
      width: 100%;

      @media (min-width: 400px) {
        top: 50%;
      }

      @include media-breakpoint-up(lg) {
        top: 0;
      }
    }
  }

  .insight-video-container {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);
    background-color: #000;
    display: none;

    @media (min-width: 400px) {
      top: calc(50% - 25px);
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-up(lg) {
      top: 0;
      height: 100%;
      width: 100%;
      transform: translateY(0);
    }
  }

  &__posh-title {
    color: $brand;
    display: inline-block;
    font-family: $font-secondary;
    font-size: 40px;
    margin-right: 30px;
    text-transform: none;

    @include media-breakpoint-up(md) {
      font-size: 60px;
    }
  }

  &__paragraph {
    margin: 0;

    @include media-breakpoint-up(md) {
      width: 60%;
    }

    @include media-breakpoint-up(lg) {
      width: 40%;
    }

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 6rem 3rem;
    }
  }
}

.go-back {
  font-size: $font-size-medium;
  font-family: $font-secondary;
  color: $black;
  display: block;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;

  @include media-breakpoint-up(md) {
    font-size: 35px;
    &:hover {
      transform: translateX(-15px);
    }
  }

  &.sm {
    font-size: 24px;
    .icon {
      font-size: 16px;
    }
  }

  &.position-top {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 30px;
    }
    .icon {
      color: $brand;
    }
  }

  .icon {
    font-size: $font-size;
    margin-right: 10px;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      margin-right: 20px;
    }
  }
}

.hero-spacing {
  display: block;
  height: 30px;

  @include media-breakpoint-up(lg) {
    height: 50px;
  }
}

.horizontal-line {
  border: 1px solid red;
  margin-bottom: 10px;
  width: 40px;
}

.persona-select {
  margin-top: 85px;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }


  &.below-hero {
    margin-top: 25px;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &:not(.below-hero) {
    opacity: 0;
    transform: translateY(15px);
    animation: heroHomeFadeIn 0.8s ease-in-out forwards;
    animation-delay: 0.2s;

    &.ie-animate {
      animation: heroHomeFadeIn 0.8s ease-in-out forwards;
      animation-delay: 0.2s;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  p {
    font-family: $font-secondary;
    font-size: 25px;
    color: $brand;
    display: inline-block;
    margin-right: 30px;

    @include media-breakpoint-up(xl) {
      font-size: 45px;
    }
  }
}

.select2-container--chase {
  background: rgba($brand, 0.03);
  min-width: 250px;
  width: calc(100% - 10vw);
  min-height: 57px;
  display: inline-block;
  position: relative;
  text-align: left;

  @include media-breakpoint-up(md) {
    width: 100%;
    max-width: 350px;
  }


  @include media-breakpoint-up(xl) {
    min-height: 92px;
    max-width: 510px;
  }


  // Selected Text
  .select2-selection--single {
    font-size: 14px;
    color: $black;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    line-height: 57px;
    padding-left: 28px;

    @include media-breakpoint-up(xl) {
      font-size: 20px;
      line-height: 92px;
      padding-left: 40px;
    }


    .select2-selection__rendered {
      padding-left: 0;
    }
  }

  .select2-selection,
  .select2-selection__rendered {
    &:focus {
      outline: none;
    }
  }

  // Arrow
  .select2-selection__arrow {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    @include media-breakpoint-up(xl) {
      right: 40px;
    }


    &:before {
      @include icons;

      content: '\e901';
      font-size: 14px;
      color: $brand;

      @include media-breakpoint-up(xl) {
        font-size: 17px;
      }
    }
  }

  // Dropdown
  .select2-dropdown {
    background: rgb(248, 250, 253);
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .select2-dropdown {
    border: none;
  }

  .select2-results__options {
    height: 220px;
    overflow-y: scroll;
    position: relative;
    padding-right: 30px;
  }

  .select2-results__option {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: $black;
    padding-left: 30px;
    margin-bottom: 15px;

    @include media-breakpoint-up(xl) {
      font-size: 20px;
      padding-left: 55px;
      margin-bottom: 35px;
    }


    &:last-child {
      margin-bottom: 0;
    }
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
