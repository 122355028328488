@import "common/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

// Slick
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/slick-carousel/slick/slick-theme.scss";

// AOS
@import "../../node_modules/aos/dist/aos.css";

// Nouislider
@import "../../node_modules/nouislider/distribute/nouislider.css";

// jquery-ui
@import "../../node_modules/jquery-ui-dist/jquery-ui.css";
@import "../../node_modules/jquery-ui-dist/jquery-ui.theme.css";

/* GLOBAL
========================================================================== */

@import "common/global";
@import "common/fonts";
@import "common/mixins";
@import "common/z-index";
@import "common/type";
@import "common/icons";
@import "common/animate";
@import "common/forms";

@import "common/select2";

/* COMPONENTS
========================================================================== */

@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";

@import "components/hero";
@import "components/homepage-carousel";
@import "components/block-anchor";
@import "components/block-service";
@import "components/block-events";
@import "components/block-half-diag";
@import "components/block-news";
@import "components/block-brand-banner";
@import "components/block-discover";
@import "components/block-content-image";
@import "components/block-advisors";
@import "components/block-awards";
@import "components/block-testimonial";
@import "components/block-video";
@import "components/block-diag-content";
@import "components/block-central-text";
@import "components/block-accordions";
@import "components/block-page-links";
@import "components/block-diag-content";
@import "components/block-partnerships";
@import "components/block-gallery-topics";
@import "components/clientlogin";
@import "components/dpm-adviser";
@import "components/dpm-benefits";
@import "components/dpm-insights";
@import "components/dpm-portfolios";
@import "components/dpm-team";

@import "components/event-search";
@import "components/membership-code";

@import "components/webinar-list-banner";


/* LAYOUTS
========================================================================== */

@import "layouts/header";
@import "layouts/navigation";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/service";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/error-page";

@import "layouts/cf7forms";
@import "layouts/arrange-a-talk";
@import "layouts/appointment";
@import "layouts/consultation";
@import "layouts/nonmember-callback";
@import "layouts/tax-and-pensions";
@import "layouts/zurich";
@import "layouts/thank-you-pages";

.progress-bar {
  background: #f01a3a;
  height: 5px;
  animation: 10s progress infinite;
}

@keyframes progress {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.insight-pips_container {
  display: flex;
  justify-content: center;

  .insight-pips {
    width: 30%;
    display: flex;
    position: absolute;
    bottom: 0;
    z-index: 9999999999;
    justify-content: space-around;

    .pip {
      color: white;
      opacity: 0.3;
      cursor: pointer;

      &.active {
        opacity: 1 !important;
      }
    }
  }
}
