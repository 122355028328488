.anchor {
  position: relative;
  a {
    position: absolute;
    left: 0;
    top: -150px;
    display: block;
    visibility: hidden;
  }
}
