// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}
#home_contact_form{
  h3{
    margin-bottom: 0px;
  }
  .wpcf7-not-valid{
    border: 1px #dc3232 solid;
  }
  .wpcf7-not-valid-tip{
    display: none;
  }
  .row{
    padding: 10px 0;
  }
}
.wpcf7 form.invalid .wpcf7-response-output {
  display: none !important;
}
