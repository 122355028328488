.homepage-slider {
  height: calc(100vh - 82px);
  position: relative;
  width: 100%;
  margin-bottom: 60px;
  @media (max-width: 991px) {
    height: calc(100vh - 52px);
  }

  @media (max-width: 767px) {
    height: 380px;
  }

  &.button-gap {
    @media(max-width: 767px) {
      margin-bottom: 110px;
    }
  }
  &[data-gap="yes"] {
    @media(max-width: 767px) {
      margin-bottom: 110px;
    }
  }

  .hero--home {
    @media(max-width: 767px) {
      align-items: center;
    }
  }

  .hero-content {
    @media (min-width: 768px) {
      padding-top: 100px;
    }

    .title,
    .title span {
      @media (max-width: 767px) {
        font-size: 24px;
      }

      @media (min-width: 768px) and (max-width: 900px) {
        font-size: 42px;
      }

      @media (min-width: 900px) {
        font-size: 48px;
      }
    }
  }

  .slick-list {
    @media (max-width: 767px) {
      overflow: visible;
    }
  }

  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-track {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      overflow: hidden;
    }
  }

  .slick-slide {
    display: flex;
  }

  .slick-dots {
    bottom: 14px;

    @media (max-width: 767px) {
      pointer-events: auto;
    }

    li {
      border: 2px solid $brand;
      width: 14px;
      height: 14px;

      &.slick-active {
        background-color: $brand;
      }
    }
  }

  &__banner {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 45%;
      width: 58%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      transform-origin: 100% 0;
      transform: skew(-30deg);
      z-index: 0;

      @media (max-width: 767px) {
        right: 8%;
        transform: skew(-22deg);
        width: 100%;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        right: 15%;
        width: 90%;
      }

      @media (min-width: 992px) and (max-width: 1200px) {
        right: 25%;
        width: 80%;
      }
    }

    h1 {
      color: $black;
      font-size: 40px;
      letter-spacing: 1px;
      margin-bottom: 20px;
      padding: 20px 20px 0 20px;
      position: relative;
      text-transform: uppercase;
      z-index: 1;

      .cursive {
        color: $brand;
        font-family: $font-secondary;
        text-transform: none;
      }

      @media (max-width: 767px) {
        font-size: 24px;
      }
    }

    p {
      color: $black;
      padding: 0 20px 20px 20px;
      position: relative;
      z-index: 1;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 360px;
      overflow: hidden;
      padding: 0 14% 0 2%;
      position: relative;
      width: 52%;
      z-index: 999;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #ffffffc2;
        transform-origin: 100% 0;
        transform: skew(-30deg);

        @media (max-width: 767px) {
          transform: skew(-22deg);
        }
      }

      @media(max-width: 767px) {
        height: 220px;
        margin: 80px 0 auto;
        padding-right: 28%;
        width: 90%;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        padding-right: 20%;
        width: 80%;
      }

      @media (min-width: 992px) and (max-width: 1200px) {
        padding-right: 20%;
        width: 70%;
      }
    }

    .bg.desktop {
      display: block;
      width: 100%;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .bg.mobile {
      display: block;

      @media (min-width: 768px) {
        display: none;
      }
    }

    .btn {
      position: absolute;
      bottom: 45px;
      left: 0;
      right: 0;
      margin: auto;
      width: 300px;
      z-index: 1;

      span {
        display: block;
        line-height: 22px;
        transform: skew(25deg);
        z-index: 2;
      }

      @media (max-width: 767px) {
        bottom: -80px;
      }
    }
  }
}
