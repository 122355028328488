/* ===============gst===========================================================

   TYPE

========================================================================== */

/* UTIL
========================================================================== */

.text-brand {

  color: $brand !important;

}

.text-black {

  color: $black !important;

}

/* HEADINGS
========================================================================== */
#{headings(1,6)} {

  color: $heading-color;

}

/* PARAGRAPH
========================================================================== */

p {

  font-size: 15px;
  line-height: 25px;
  font-family: $font-base;

  @include media-breakpoint-up(xxl) {

    font-size: 17px;
    line-height: 1.7;

  }

}

ul, ol {

  li {

    font-size: 15px;

    @include media-breakpoint-up(xxl) {
      font-size: 17px;
    }

  }

}

/* Links
========================================================================== */

a {

  text-decoration: none;
  transition: color 0.3s ease-in-out;
  color: $brand;

  &:hover {

    text-decoration: none;

  }

  &:focus {

    outline: none;

  }


}

.cat-link {

  border: 1px solid $brand;
  color: $brand;
  text-align: center;
  padding: 10px 30px;
  font-size: 14px;
  display: inline-block;

  @include media-breakpoint-up(md) {

    font-size: 19px;

  }

}

.line-height-md,
.line-height-md p {
  line-height: 2;
  margin-bottom: 2rem;
  @include media-breakpoint-up(md) {
    line-height: 2.4;
    margin-bottom: 3rem;
  }
}

.line-height-relaxed-all {
  line-height: 1.6;
}

.line-height-relaxed {
  @include media-breakpoint-up(md) {
    line-height: 1.6;
  }
}

.icon-small {
  font-size: 10px;
}

.icon-large {
  font-size: 20px;
}

.leading-compact {
  letter-spacing: .1rem;
}

.leading-tight {
  letter-spacing: .15rem;
}

.leading-relaxed {
  letter-spacing: .2rem;
}
